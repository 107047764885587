import React, { Component } from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Recaptcha from 'react-google-recaptcha'
import { serialize } from 'dom-form-serializer'

import { Row, Col, Input, Icon, Button, Form, Alert } from 'antd'
const { TextArea } = Input;

const RECAPTCHA_KEY = '6LfhJ5oUAAAAALvxuBvcjCw576po_atu8-qxSLhM';

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Contact extends Component {
    static defaultProps = {
        name: 'Simple Form Ajax',
        subject: '', // optional subject of the notification email
        action: '',
        successMessage: 'Votre message a bien été envoyé !',
        errorMessage:
          "Il y a eu un problème, votre message n'a pas pu être envoyé, merci de nous contacter par mail."
    }

    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
            name: "",
            email: "",
            message:"",
            alert: '',
            disabled: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    };

    handleRecaptcha = value => {
        this.setState({ "g-recaptcha-response": value });
    };

    handleSubmit = event => {
        event.preventDefault()
        if (this.state.disabled) return
    
        const form = event.target
        const data = serialize(form)
        this.setState({ disabled: true })
        // fetch(form.action + '?' + stringify(data), {
        //   method: 'POST'
        // })
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
          .then(res => {
            if (res.ok) {
              return res
            } else {
              throw new Error('Network error')
            }
          })
          .then(() => {
            form.reset()
            this.setState({
              alert: this.props.successMessage,
              disabled: false
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({
              disabled: false,
              alert: this.props.errorMessage
            })
          })
      }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='legal'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <div style={{textAlign:"center", margin:"50px"}}>
                        <h1 className="title" style={{textAlign:"center"}}>CONTACTEZ-NOUS</h1>
                    </div>
                    <Row className="container"  type="flex" justify="space-around" align="top" gutter={50}>
                        <Col xs={34} sm={34} md={16} lg={16} xl={16}>
                        <Form id="contact" name="contact" method="post" onSubmit={this.handleSubmit} action="/" data-netlify-recaptcha="true" netlify="true">
                            <noscript>
                                <p>This form won’t work with Javascript disabled</p>
                            </noscript>
                            {this.state.alert && (
                                <Alert message={this.state.alert} type="success" showIcon />
                             )}
                            <Form.Item>
                                <Input type="text" name="name" placeholder="Nom" size="large" onChange={this.handleInputChange}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('email', {
                                    rules: [{
                                    type: 'email', message: "Ce n'est pas une adresse Email valide !",
                                    }, {
                                    required: true, message: 'Remplissez le champ Email',
                                    }]
                                })( 
                                    <Input type="email" name="email" placeholder="Email" size="large" onChange={this.handleInputChange} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <TextArea name="message" rows={5} onChange={this.handleInputChange}/>
                            </Form.Item>
                            <div data-netlify-recaptcha="true"></div>
                                <Recaptcha
                                    ref="recaptcha"
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={this.handleRecaptcha}
                                />
                            <br/>
                            <Form.Item>
                              <Button type="primary" htmlType="submit">Envoyer</Button>
                            </Form.Item>
                        </Form>
                        </Col>
                    </Row>
                    <Footer/>
                </div>
            </Layout>
        );
    }
}

const ContactForm = Form.create({ name: 'contact' })(Contact);

export default ContactForm;